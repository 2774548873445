<template>
  <div class="carloan-box">
    <img class="carloan-bg" src="../assets/images/carloan_bg.png" />
    <div class="carloan-card">
      <van-form @submit="onSubmit">
        <van-field
          v-model="params.name"
          name="name"
          label="姓名"
          placeholder="请输入您的姓名"
          input-align="right"
          autocomplete="off"
        />

        <van-field
          readonly
          clickable
          name="applyMoney"
          :value="params.applyMoney"
          label="申请金额"
          placeholder="请选择"
          @click="showPickerMoney = true"
          input-align="right"
          :right-icon="showPickerMoney ? 'arrow-up' : 'arrow-down'"
        />

        <van-field
          v-model="params.brand"
          name="brand"
          label="车辆品牌"
          placeholder="请输入车辆品牌"
          input-align="right"
          autocomplete="off"
        />

        <van-field
          v-model="params.price"
          name="price"
          label="裸车购买价"
          placeholder="请输入裸车购买价（万）"
          input-align="right"
          type="number"
          autocomplete="off"
        >
          <template #button>万元</template>
        </van-field>

        <van-field
          readonly
          clickable
          name="licenseYear"
          :value="params.licenseYear"
          label="上牌年份"
          placeholder="请选择"
          @click="showPickerYear = true"
          input-align="right"
          :right-icon="showPickerYear ? 'arrow-up' : 'arrow-down'"
        />

        <van-field
          readonly
          clickable
          name="licenseMonth"
          :value="params.licenseMonth"
          label="上牌月份"
          placeholder="请选择"
          @click="showPickerMonth = true"
          input-align="right"
          :right-icon="showPickerMonth ? 'arrow-up' : 'arrow-down'"
        />

        <van-field
          readonly
          clickable
          name="liveCity"
          :value="params.liveCity"
          label="居住城市"
          placeholder="请选择"
          @click="showPickerCity = true"
          input-align="right"
          :right-icon="showPickerCity ? 'arrow-up' : 'arrow-down'"
        />

        <div class="submit-btn">
          <van-button block native-type="submit" :disabled="disabled">
            立即申请
          </van-button>
        </div>

        <span class="submit-tips">*我们郑重承诺，以上信息严格保密</span>
      </van-form>
    </div>

    <!-- 三步极速借款 -->
    <!-- <div class="step">
      <van-row type="flex" justify="center" align="center">
        <van-col span="8" class="left">
          <img class="line" src="../assets/images/icon_line.png" />
        </van-col>
        <van-col span="8" class="step-content">三步极速借款</van-col>
        <van-col span="8" class="right">
          <img
            class="transform-180 line"
            src="../assets/images/icon_line.png"
          />
        </van-col>
      </van-row>
      <ul class="step-ul">
        <li v-for="(item, index) in steps" :key="index" class="step-li">
          <img
            :class="1 === index || 3 === index ? 'item-line' : 'img-item'"
            :src="item.img"
          />
          <h3 class="step-h">{{ item.content }}</h3>
          <span class="step-span">{{ item.tips }}</span>
        </li>
      </ul>
    </div> -->

    <!-- 借款保障 -->
    <div class="carloan-card">
      <div class="advantage">
        <van-row type="flex" justify="center" align="center">
          <van-col span="8" class="left">
            <img class="line" src="../assets/images/icon_line.png" />
          </van-col>
          <van-col span="8" class="step-content">三大保障</van-col>
          <van-col span="8" class="right">
            <img
              class="transform-180 line"
              src="../assets/images/icon_line.png"
            />
          </van-col>
        </van-row>
        <ul class="advantage-ul">
          <li
            class="advantage-li"
            :class="1 === index ? 'row-reverse' : ''"
            v-for="(item, index) in advantages"
            :key="index"
          >
            <img class="advantage-img" :src="item.img" />
            <div class="advantage-div">
              <h1 class="advantage-h1">{{ item.num }}</h1>
              <div>
                <h3 class="advantage-h3">{{ item.content }}</h3>
                <span class="advantage-span">{{ item.tips }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- 金额下拉选 -->
    <van-popup
      v-model="showPickerMoney"
      position="bottom"
      safe-area-inset-bottom
      round
    >
      <van-picker
        title="请选择金额范围"
        show-toolbar
        :columns="moneys"
        @confirm="onConfirmMoney"
        @cancel="showPickerMoney = false"
      />
    </van-popup>

    <!-- 年份下拉选 -->
    <van-popup
      v-model="showPickerYear"
      position="bottom"
      safe-area-inset-bottom
      round
    >
      <van-picker
        title="请选择上牌年份"
        show-toolbar
        :columns="years"
        @confirm="onConfirmYear"
        @cancel="showPickerYear = false"
        :default-index="getArrayIndex(years, currentYear)"
      />
    </van-popup>

    <!-- 月份下拉选 -->
    <van-popup
      v-model="showPickerMonth"
      position="bottom"
      safe-area-inset-bottom
      round
    >
      <van-picker
        title="请选择上牌月份"
        show-toolbar
        :columns="months"
        @confirm="onConfirmMonth"
        @cancel="showPickerMonth = false"
        :default-index="getArrayIndex(months, `${currentMonth}`)"
      />
    </van-popup>

    <!-- 地区下拉选 -->
    <van-popup
      v-model="showPickerCity"
      position="bottom"
      safe-area-inset-bottom
      round
    >
      <van-picker
        show-toolbar
        title="请选择居住城市"
        :columns="areaList"
        :loading="loading"
        @change="onChange"
        @confirm="onAreaConfirm"
        @cancel="showPickerCity = false"
        value-key="localName"
      />
    </van-popup>
  </div>
</template>
<script>
import * as API_Common from "@/api/common.js";

export default {
  name: "CarLoan",
  data() {
    return {
      params: {
        name: "",
        applyMoney: "",
        brand: "",
        price: "",
        licenseYear: "",
        licenseMonth: "",
        liveCityId: undefined,
        liveCity: undefined,
      },
      moneys: ["1-5万", "5-10万", "5-20万", "20万以上"],
      years: [],
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      showPickerMoney: false,
      showPickerYear: false,
      showPickerMonth: false,
      showPickerCity: false,
      loading: true,
      areaList: [{ values: [] }, { values: [] }],
      currentYear: new Date().getFullYear(),
      currentMonth:
        new Date().getMonth() + 1 < 10
          ? `0${new Date().getMonth() + 1}`
          : new Date().getMonth() + 1,
      steps: [
        {
          img: require("../assets/images/icon_edsq.png"),
          content: "额度申请",
          tips: "线上提交资料",
        },
        {
          img: require("../assets/images/icon_step.png"),
          content: "",
          tips: "",
        },
        {
          img: require("../assets/images/icon_jssh.png"),
          content: "极速审核",
          tips: "最快10分钟审核",
        },
        {
          img: require("../assets/images/icon_step.png"),
          content: "",
          tips: "",
        },
        {
          img: require("../assets/images/icon_sdfk.png"),
          content: "闪电放款",
          tips: "最快一小时到账",
        },
      ],
      advantages: [
        {
          img: require("../assets/images/icon_01step.png"),
          num: "01",
          content: "高额度放款",
          tips: "最高可贷20万",
        },
        {
          img: require("../assets/images/icon_02step.png"),
          num: "02",
          content: "超低日息",
          tips: "最低日系率0.02%",
        },
        {
          img: require("../assets/images/icon_03step.png"),
          num: "03",
          content: "门店多",
          tips: "100+网点",
        },
      ],
    };
  },
  created() {
    this.getYearCollection();

    this.getArea(0, 0);
  },
  computed: {
    disabled() {
      const {
        name,
        applyMoney,
        brand,
        price,
        licenseYear,
        licenseMonth,
        liveCityId,
      } = this.params;
      return (
        name &&
        applyMoney &&
        brand &&
        price &&
        licenseYear &&
        licenseMonth &&
        !liveCityId
      );
    },
  },
  watch: {
    "params.price"(val) {
      if (val.toString().includes("-")) {
        this.params.price = val.toString().replace(/-/g, "");
      }
    },
  },
  methods: {
    onConfirmMoney(value) {
      this.params.applyMoney = value;
      this.showPickerMoney = false;
    },

    onConfirmYear(value) {
      this.params.licenseYear = value;
      this.showPickerYear = false;
    },

    getYearCollection() {
      let currentYear = this.currentYear;
      for (let index = currentYear - 15; index < currentYear + 5; index++) {
        this.years.push(index);
      }
    },

    onConfirmMonth(value) {
      this.params.licenseMonth = value;
      this.showPickerMonth = false;
    },

    onSubmit(values) {
      const { liveCity } = values;
      let obj = {
        ...values,
        ...{
          liveCity: liveCity.split(" ")[1],
          liveCityId: this.params.liveCityId,
        },
      };
      API_Common.loanApply(obj).then((res) => {
        if (res.code === 200) {
          this.$toast.success("提交成功");
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    getArea(parentId, index) {
      this.loading = true;
      API_Common.getRegions(parentId).then((res) => {
        if (200 === res.code) {
          this.areaList[index].values = [{ localName: "请选择" }, ...res.data];
          this.areaList = [...this.areaList];
          this.loading = false;
        } else {
          this.$toast.fail("获取省份失败");
        }
      });
    },

    onChange(picker, values, index) {
      if (values[index].localName === "请选择" && index !== 1) {
        this.areaList[1].values = [{ localName: "请选择" }];
      } else {
        if (!index) {
          this.getArea(values[0].id, index + 1); //传参 参数为上层选择的地区的id
        }
      }
    },

    onAreaConfirm(picker) {
      if (
        picker[0].localName === "请选择" ||
        picker[1].localName === "请选择"
      ) {
        this.$toast.fail("请选择完整的省市");
        return false;
      } else {
        this.params.liveCity = `${picker[0].localName} ${picker[1].localName}`;
        this.params.liveCityId = picker[1].id;
        this.showPickerCity = false;
      }
    },

    getArrayIndex(arr, obj) {
      let i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.carloan-box {
  background: #f26014;
}
.carloan-bg {
  width: 100%;
  height: 6.88rem;
}
.submit-btn {
  padding: 0 0.32rem;
  margin: 0.96rem 0 0.3rem;
}
.submit-tips {
  text-align: center;
  display: block;
  font-size: 0.24rem;
  color: #c3c2c6;
}
.step {
  border-top: 0.2rem solid #f8f8f8;
  border-bottom: 0.2rem solid #f8f8f8;
  padding: 0.72rem 0 0.9rem;
}

.carloan-card {
  margin: 0.36rem;
  background: #ffffff;
  border-radius: 0.24rem;
  overflow: hidden;
  position: relative;
  top: -1.12rem;
}

.line {
  width: 0.84rem;
  height: 0.16rem;
}
.transform-180 {
  transform: rotate(180deg);
}
.step-content {
  font-size: 0.36rem;
  font-weight: 500;
  text-align: center;
  color: #fb8d0b;
}
.left {
  display: flex;
  justify-content: flex-end;
}
.right {
  display: flex;
  justify-content: flex-start;
}
.step-ul {
  margin-top: 0.72rem;
  display: flex;
  justify-content: center;
  padding: 0 0.36rem;
}
.step-li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.step-li:nth-child(2),
.step-li:nth-child(4) {
  padding-top: 0.1rem;
  justify-content: space-around;
}

.img-item {
  width: 0.84rem;
  height: 0.84rem;
}
.item-line {
  width: 0.9rem;
  height: 0.14rem;
}
.step-h {
  font-size: 0.26rem;
  font-weight: 500;
  color: #3a3a3a;
  margin: 0.08rem 0;
}
.step-span {
  font-size: 0.24rem;
  color: #c3c2c6;
}
.advantage {
  padding: 0.6rem 0 1.2rem;
}
.advantage-ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.advantage-li {
  display: flex;
  margin-top: 0.68rem;
  width: 6rem;
}
.row-reverse {
  flex-direction: row-reverse;
  margin-right: 0;
}

.row-reverse {
  flex-direction: row-reverse;

  .advantage-img {
    margin-right: 0;
    margin-left: 0.88rem;
  }
}

.advantage-img {
  width: 1.8rem;
  height: 1.94rem;
  margin-right: 0.88rem;
}
.advantage-div {
  display: flex;
}
.advantage-h1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.31;
  font-size: 1.08rem;
  font-weight: 600;
  color: #fb8d0b;
  margin-right: 0.24rem;
}
.advantage-h1 + div {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}
.advantage-h3 {
  font-size: 0.32rem;
  font-weight: 500;
  color: #3a3a3a;
  margin-bottom: 0.12rem;
}
.advantage-span {
  font-size: 0.24rem;
  color: #c3c2c6;
}
::v-deep {
  .van-form {
    padding-bottom: 0.6rem;
  }
  .van-cell {
    padding-top: 0.31rem;
    padding-bottom: 0.31rem;
    color: #3a3a3a;
    font-size: 0.28rem;
  }
  .van-field__label,
  .van-field__button {
    color: #3a3a3a;
  }
  .van-field__right-icon {
    color: #c3c2c6;
  }
  .van-picker__confirm {
    color: #fb8302;
  }
  .van-button {
    height: 0.9rem;
    background: linear-gradient(90deg, #ffbb31, #fb8302);
    border-radius: 0.08rem;
    font-size: 0.32rem;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>
